import React from 'react'

import { Container } from '../Container'

export const HomeCarousel = () => {
  return (
    <>
      <div className="pt-36 pb-10 bg-gray-300 bg-banner-homepage bg-cover bg-no-repeat bg-center md:pt-52 md:pb-20">
        <Container>
          <h1 className="max-w-3xl mb-20">
            <div className="text-5xl md:text-7xl font-bold">Discover stories </div>
            <div className="text-5xl font-serif md:text-7xl">
              about{' '}
              <i className="italic">
                web enthusiasts<sup className="top-0">*</sup>
              </i>{' '}
              around the world
            </div>
          </h1>
        </Container>
      </div>
    </>
  )
}
