import React from 'react'

import { LinkCustom } from '../LinkCustom'

export const SeeMore = ({ text, path }) => {
  return (
    <div className="pt-10 text-right min-w-full">
      <LinkCustom to={path} className="flex justify-end hover:underline">
        <span className="mr-2">
          See All <span className="font-bold">{text}</span>
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="block h-6 w-6"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </LinkCustom>
    </div>
  )
}
