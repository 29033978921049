import * as React from 'react'

import { graphql } from 'gatsby'

import { BannerPodcast } from '../components/BannerPodcast'
import { Container } from '../components/Container'
import { ContinentsList } from '../components/ContinentsList'
import { EpisodeList } from '../components/EpisodeList'
import { HomeCarousel } from '../components/HomeCarousel'
import { PeopleList } from '../components/PeopleList'
import { SeeMore } from '../components/SeeMore'
import { Seo } from '../components/Seo'

const IndexPage = ({ data }) => {
  const { latestEpisodes, carouselEpisodes, guests } = data

  return (
    <>
      <Seo
        title="World Web Stories - Stories of web enthusiasts around the world "
        template={``}
        pathname="/"
      />

      <section>
        <HomeCarousel podcasts={carouselEpisodes} />
      </section>

      <div className="bg-white pt-10">
        <Container>
          <h2 className="text-2xl font-medium leading-8 mb-7 sm:leading-10 sm:text-4xl text-gray-900">
            Episodes by continents
          </h2>
        </Container>

        <ContinentsList />
      </div>

      <Container className="pt-10">
        <h2 className="text-2xl font-medium leading-8 mb-7 sm:leading-10 sm:text-4xl text-gray-900">
          Latest Episodes
        </h2>
        <EpisodeList podcasts={latestEpisodes} />

        <SeeMore text="Latest Episodes" path="/episodes" />
      </Container>

      <BannerPodcast />

      <Container className="pt-10">
        <h2 className="text-2xl font-medium leading-8 mb-7 sm:leading-10 sm:text-4xl text-gray-900">
          Latest Hosts
        </h2>

        <PeopleList people={guests} />

        <SeeMore text="Guests" path="/guests" />
      </Container>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    latestEpisodes: allMdx(
      filter: {
        frontmatter: { active: { eq: true } }
        fileAbsolutePath: { regex: "/(/episodes/)/" }
      }
      sort: { fields: frontmatter___publicationDate, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            duration
            url
            episodeNumber
            publicationDate
            imageCover
            country
          }
        }
      }
    }
    guests: allAirtableGuests(
      sort: { fields: data___Release_Date_Episode, order: DESC }
      filter: { data: { Release_Date_Episode: { ne: null }, Episode: { ne: null } } }
    ) {
      nodes {
        data {
          Full_name
          Avatar___profile_image {
            thumbnails {
              large {
                url
              }
            }
          }
          Title
          Country {
            data {
              Name_country
            }
          }
          Personal_website___blog
          Code_country
          Twitter
          LinkedIn
          Instagram
          Github
          Facebook_profile
          Bio
          Url_episode__from_Episode_
        }
      }
    }
  }
`
