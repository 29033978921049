import React from 'react'

import { StaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { v4 as uuid } from 'uuid'

import { Container } from '../Container'
import { LinkCustom } from '../LinkCustom'

const Banner = ({ data }) => {
  const { nodes } = data.allPodcastsYaml

  return (
    <Container>
      <div className="py-12 lg:py-16">
        <div className="px-6 py-6 bg-primary rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
          <div className="xl:w-0 xl:flex-1">
            <h2 className="text-2xl font-medium tracking-tight text-white sm:text-3xl">
              Join us every week on every major platform
            </h2>
            <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-100">
              Listen to web passionates and professionnels who come from all over the globe. They
              share their life stories from where they live!
            </p>
          </div>
          <div className="mt-8 grid gap-1 sm:grid-cols-2 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
            {nodes.map((platform) => (
              <div className="mx-2" key={uuid()}>
                {platform.img && (
                  <LinkCustom href={platform.url} title={`Listen on ${platform.name}`}>
                    <GatsbyImage key={uuid} image={getImage(platform.img)} alt={platform.name} />
                  </LinkCustom>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  )
}

export const BannerPodcast = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allPodcastsYaml {
            nodes {
              name
              url
              img {
                childImageSharp {
                  gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
      `}
      render={(data) => <Banner data={data} {...props} />}
    />
  )
}
